const PageNotFound = () => {
    return (
        <div style={{padding: "5%"}}>
            <h1>404</h1>
            <h2>Page Not Found</h2>
            We are currently working on this functionality!
        </div>
    )
}

export default PageNotFound;