const theme = {
  color: {
    primary: "#fff",
    accent: "#36863A",
    secondary: "#133F2E",
    light: "#EBEBEB",
    error: "#d43646",
  },
  borderRadius: ["10px", "15px", "20px"],
  shadow: {
    menuBar: "0px 1px 18px 0px rgba(19, 63, 46, 0.2)",
    message: "0px 10px 15px 0px rgba(19, 63, 46, 0.05)",
  },
};

export default theme;
